<script setup>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import ELZindexProvider from 'element-plus'
const WIDTH = 1400
const meta = ref(null)

const updateViewportMeta = () => {
  if (!meta.value) return

  let scale = screen.width / WIDTH
  console.log(screen.width, scale, 2)
  let content = `width=${WIDTH}, initial-scale=${scale > 1 ? 1 : scale}, maximum-scale=1, minimum-scale=${scale > 1 ? 1 : scale}`
  meta.value.setAttribute('content', content)
}

onMounted(() => {
  meta.value = document.querySelector('meta[name=viewport]')
  if (!meta.value) {
    meta.value = document.createElement('meta')
    meta.value.setAttribute('name', 'viewport')
    document.head.appendChild(meta.value)
  }

  window.addEventListener('resize', updateViewportMeta)
  updateViewportMeta()
  // 检测是否是移动设备
  if (/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    // 如果是移动设备，跳转到移动端网址
    if (location.hostname.includes('m.') || location.hostname.includes('ok-skins.com') || location.hostname.includes('c5game.com') || location.hostname.includes('steamdt.com'))
      return
    window.location.href = `https://m.${location.hostname}${location.pathname}${location.search}${location.hash}`
  } else {
    // 如果是桌面设备，跳转到桌面端网址
    if (location.hostname.includes('m.')) {
      const desktopHostname = location.hostname.replace('m.', '')
      window.location.href = `https://${desktopHostname}${location.pathname}${location.search}${location.hash}`
    }
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', updateViewportMeta)
})
</script>
<template>
  <ElConfigProvider :locale="zhCn">
    <ElConfigProvider>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </ElConfigProvider>
  </ElConfigProvider>
</template>

<style lang="scss">
:root {
  --el-border-radius-base: 10px;
}
</style>
